<template>
  <div id="AutorizacaoEscrituraCriacaoModal">
    <v-card class="container pa-0">
      <v-card-title v-bind:style="{ 'background-color':COR_PRINCIPAL }"
      class="justify-space-between py-2 px-3">
        <span class="title-page body-1">Criar Autorização de Escritura</span>
          <v-btn
          @click="store_Proposta.dialogAutorizacaoEscrituraCriacao = false"
          icon
          dark
          color="#F2F6F7"
          >
            <v-icon large class="title">mdi-close</v-icon>
          </v-btn>
      </v-card-title>

      <v-card-text class="wrapper pt-1 pb-10">
        <v-container>
          <v-row>
            <v-col class="px-0">
              <v-form 
                ref="form"
                v-model="valid">

                <v-text-field
                  v-model="dados.numero_autorizacao"
                  class="mb-0"
                  light
                  outlined
                  loader-height="1"
                  background-color="#FFF"
                  label="Número da autorização"
                  placeholder="Número da autorização"
                  filled
                  required
                  dense
                  :rules="numeroRules"
                ></v-text-field>

                <v-text-field
                  v-model="dados.titular"
                  class="mt-n2"
                  light
                  loader-height="1"
                  background-color="#FFF"
                  label="Titular"
                  placeholder="Titular"
                  filled
                  outlined
                  required
                  readonly
                  dense
                  :rules="titularRules">
                  <template v-slot:append-outer>
                    <v-icon @click="abrirDialogoPessoa()" color="primary"
                    >mdi-magnify</v-icon>
                  </template>
                </v-text-field>

                <DatePicker
                  v-model="dados.lavratura_dt"
                  label="Data da autorização"
                  placeholder="dia/mês/ano"
                  class="mr-0 mt-n2"
                  outlined
                  backgroundColor="#FFF"
                  tabindex="0"
                  dense
                  :rules="dataAutorizacaoRules"
                  format="DD/MM/YYYY">
                </DatePicker>


                <v-textarea
                  v-model="dados.observacoes"
                  rows="1"
                  auto-grow
                  class="mt-n2"
                  counter="500"
                  maxlength="500"
                  background-color="#FFF"
                  label="Observações"
                  outlined
                  placeholder="Observações"
                  filled
                  dense>
                </v-textarea>

              </v-form>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-footer absolute color="#F2F6F7" elevation="0" class="d-flex justify-center pa-3">
        <v-btn
        @click="store_Proposta.dialogAutorizacaoEscrituraCriacao = false"
        class="mr-4 btn caption font-weight-medium"
        color="primary"
        text
        >
          Cancelar
        </v-btn>

        <v-btn
        :disabled="!valid"
        :loading="loading"
        @click="validate()"
        class="btn white--text caption font-weight-medium"
        color="primary accent-4"
        >
          Salvar
        </v-btn>
      </v-footer>
    </v-card>
    <!-- DIALOGO PESSOA -------------------------------------------------------->
    <v-container v-if="store_Pessoa.dialogPessoa">      
      <v-dialog
        v-model="store_Pessoa.dialogPessoa"
        scrollable
        max-width="500px"
        max-height="600px"
      >
        <v-card>
          <!-- Cabecalho da PESSOA -->
          <v-card-title :style="{ 'background-color':COR_PRINCIPAL }">
            <v-layout nowrap class="mt-n2" style="width:100%; height:28px">
              <div class="pa-0" style="width:100%">
                <p style="color:white; white-space: pre-line; font-size:16px !important;">
                  Busca de Pessoa
                </p>                      
              </div>
              <div class="pa-0 text-center" style="width:40px;">
                <v-btn icon dark v-on:click="store_Pessoa.dialogPessoa = false" class="mt-n2">
                  <v-icon large class="title">mdi-close</v-icon>
                </v-btn>
              </div>
            </v-layout>
          </v-card-title>
          
          <!-- Corpo do PESSOA -->
          <v-card-text class="pa-0" style="height: 500px;">
            <PessoaConsultaMobile
              class="mt-n2"
              :editar_="true"
            />
          </v-card-text>

          <v-divider></v-divider>
          
          <!-- Rodape do PESSOA -->
          <v-card-actions class="d-flex justify-center pa-3" :style="{ 'background-color':COR_SECUNDARIA }">
            <v-btn
              @click="store_Pessoa.dialogPessoa = false; store_Pessoa.acao_abas = false"
              class="mr-4 btn text-none"
              color="primary"
              text
            >
              Cancelar
            </v-btn>
            <v-btn
              class="btn text-none"
              color="primary"
              :disabled="!store_Pessoa.pessoa_selecionado"
              @click="selecionarPessoa(store_Pessoa.pessoa_selecionado)"
            >
              Selecionar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <!-- FIM DIALOGO PESSOA ---------------------------------------------------->
  </div>
</template>

<script>
import store_Proposta from "../store_Proposta";
import store_Pessoa from "../../Pessoas/store_Pessoa";
import store_site from "../../../store/store_site";
import PessoaConsultaMobile from "../../Pessoas/PessoaConsultaMobile.vue"
import DatePicker from "../../ComponentesGlobais/Date-Picker/date-picker.vue";
import { COR_PRINCIPAL, COR_SECUNDARIA, } from "../../../services/constantes";
import moment from "moment"

export default {
  name: "AutorizacaoEscrituraCriacaoModal",

  props: {
  },

  components: {
    PessoaConsultaMobile,
    DatePicker
  },

  data() {
    return {

      store_Proposta      : store_Proposta,
      store_Pessoa        : store_Pessoa,
      store_site          : store_site,
      COR_PRINCIPAL       : COR_PRINCIPAL,
      COR_SECUNDARIA      : COR_SECUNDARIA,
      loading             : false,

      dados: {
        cod_pessoa: null,
        numero_autorizacao: null,
        lavratura_dt : moment().format(),
        titular: null,
        // modelo: null,
        observacoes: "",
      },

      /* Titular */
      nomes_titular: [
         "Fernando Garcia", "Bruno Duarte", "João Feliciano" 
      ],

      /* Inputs e Menus Data */
      autorizacao_dt: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menuAutorizacao_dt: false,

      /* SELECT AUTORIZACAO */
      modelo_autorizacao: [
        "RESCISÃO - Santa Lucia (modelo 1)",
      ],

      valid: true,
      form: false,

      numeroRules: [
        (value) => !!value || "O número é obrigatório",
        (value) => /^\d+$/.test(value) || 'O número não é válido',
        (value) =>
          (value && value.length <= 10) ||
          "O número deve ter menos do que 10 caracteres",
      ],
      titularRules: [
        (value) => !!value || "O titular é obrigatório",
      ],
      // modeloRules: [
      //   // (value) => !!value || "O modelo é obrigatório",
      // ],
      dataAutorizacaoRules: [
        (value) => !!value || "A data da autorizacao é obrigatório",
      ],
    };
  },

  mounted(){
    if (!!this.store_Proposta.proposta_selecionado.pessoa_venda) {
      this.dados.titular = this.store_Proposta.proposta_selecionado.pessoa_venda.pessoa_nome
      this.dados.cod_pessoa = this.store_Proposta.proposta_selecionado.pessoa_venda.cod_pessoa
    }
  },
  methods: {
    selecionarPessoa(elem) {
      // este método seleciona a pessoa
      this.dados.titular = elem.pessoa_nome
      this.dados.cod_pessoa = elem.cod_pessoa
     
      this.store_Pessoa.dialogPessoa = false
    },

    async abrirDialogoPessoa(){
      this.store_Pessoa.dialogPessoa = true
    },

    async validate () {
      this.store_Proposta.docAutorizacaoEscritura = 1;

      const lb_valido = this.$refs.form.validate();

      if (lb_valido){
        
        var lo_JSON = {
          cod_empreendvenda               : Number(this.$route.params.cod_empreendvenda),
          empreendvenda_aut_prenumero     : this.dados.numero_autorizacao,
          empreendvenda_aut_titular       : this.dados.cod_pessoa,
          empreendvenda_aut_data          : this.dados.lavratura_dt,
          empreendvenda_aut_obs           : this.dados.observacoes,
          // empreendvenda_aut_texto         : this.dados.modelo,
          cod_empreendimento              : this.store_Proposta.proposta_selecionado.cod_empreendimento
        };
      
        var ls_Res;
        this.loading = true;
       
        ls_Res = await this.store_Proposta.AutorizacaoPut(lo_JSON);

        if (ls_Res.result && ls_Res.result.message && ls_Res.result.message == 'success') {
          this.store_site.alert_cor = "#00A000";
          this.store_site.alert_timeout = 10000;
          this.store_site.alert_msg = ls_Res.result.result.trim();
          this.store_site.alert = true;

          this.store_Proposta.proposta_selecionado.empreendvenda_aut_prenumero = this.dados.numero_autorizacao
          this.store_Proposta.proposta_selecionado.empreendvenda_aut_titular   = this.dados.cod_pessoa
          this.store_Proposta.proposta_selecionado.empreendvenda_aut_data      = this.dados.lavratura_dt
          // this.store_Proposta.proposta_selecionado.empreendvenda_aut_texto     = this.dados.modelo
          this.store_Proposta.proposta_selecionado.empreendvenda_aut_obs       = this.dados.observacoes
          this.store_Proposta.proposta_selecionado.empreendvenda_autorizacao   = ls_Res.result.data[1][0].empreendvenda_autorizacao
          this.store_Proposta.proposta_selecionado.pess_aut = {}
          this.store_Proposta.proposta_selecionado.pess_aut = { cod_pessoa : this.dados.cod_pessoa, pessoa_nome : this.dados.titular }
          
        } else {
          this.store_site.alert_cor = "#A00000";
          this.store_site.alert_timeout = 20000;
          this.store_site.alert_msg = ls_Res.errors.trim();
          this.store_site.alert = true;
        }
        this.loading = false;
        this.store_Proposta.dialogAutorizacaoEscrituraCriacao = false
      }
    },
  },
};
</script>

<style scoped>
.container {
  background: #F2F6F7 !important;
}

.title-page {
  font-family: "Open Sans", sans-serif!important;
  color: #FFFFFF;
  letter-spacing: 0px;
}

.wrapper {
  height: 460px;
  overflow-y: auto;
}

/* ---------- BARRA DE SCROLL ---------- */
.wrapper::-webkit-scrollbar {
  width: 5px;
}

.wrapper::-webkit-scrollbar-button {
  padding: 1px;
}

.wrapper::-webkit-scrollbar-thumb {
  background: #CECECE; 
  border-radius: 50px;
  height: 10px!important;
}

.wrapper::-webkit-scrollbar-thumb:hover {
  background: #A7A7A7; 
  height: 150px;
} 

.btn {
  width: 100px;
}
</style>