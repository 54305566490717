<template>
  <div id="AutorizacaoEscrituraCriacao">

    <!-- TÍTULO DA PÁGINA -->
    <v-toolbar-title v-bind:style="{ 'color':COR_SUBTITULO }" class="body-1 font-primary ml-2 mb-4">
      <p class="font-primary font-weight-bold font-weight-medium body-1 mt-2" v-bind:style="{ 'color':COR_SUBTITULO }">
        Autorização de Escritura
      </p>
    </v-toolbar-title>
    <!-- FIM TÍTULO DA PÁGINA -->

    <div class="container">
      <v-row class="mx-0">
        <v-col cols="12" class="d-flex flex-column">
          <span>
            Temporibus aspernatur et libero esse est voluptatem minima. Occaecati ratione quis maxime et et rerum. Eum animi ut dolore tenetur numquam numquam corrupti eligendi. Saepe dolore ex ea porro impedit mollitia iste qui. Quod eveniet labore.
          </span>
        </v-col>

        <v-col class="d-flex">
          <v-btn
          @click="store_Proposta.dialogAutorizacaoEscrituraCriacao = true" 
          class="caption font-weight-medium white--text mx-auto"
          color="primary accent-4"
          >
            Criar Autorização
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <v-dialog
      v-model="store_Proposta.dialogAutorizacaoEscrituraCriacao"
      transition="dialog-bottom-transition"
      max-width="400"
      class="pa-0"
    >
      <AutorizacaoEscrituraCriacaoModal />
    </v-dialog>
  </div>
</template>

<script>
import store_Proposta from "../store_Proposta";
import { COR_SUBTITULO } from "../../../services/constantes";
import AutorizacaoEscrituraCriacaoModal from "../AutorizacaoEscritura/AutorizacaoEscrituraCriacaoModal.vue";
import { API } from "../../../services/API"

export default {
  name: "AutorizacaoEscrituraCriacao",

  components: {
    AutorizacaoEscrituraCriacaoModal,
  },

  data() {
    return {

      store_Proposta  : store_Proposta,

      COR_SUBTITULO   : COR_SUBTITULO,  
      API             : API,
      array_modelos   : null,
    };
  },

  mounted(){
    // this.modelosGet()
  },
  methods:{
    // async modelosGet(){
    //   const p_param = { tipo : 'autorizacao', cod_empreendimento: this.store_Proposta.proposta_selecionado.cod_empreendimento } 
    //   console.log("🚀 ~ file: AutorizacaoEscrituraCriacao.vue:76 ~ modelosGet ~ p_param:", p_param)
    //   const resposta = await API.get("modelo_documento", {params: p_param});
    //   if (resposta.data.result == "Não possui dados") {
    //     this.array_modelos = []
    //   }
    //   else{
    //     this.array_modelos =  resposta.data.result
    //   }
    // },
  }
};
</script>

<style scoped>
#AutorizacaoEscrituraCriacao {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  clear: both;
  padding-top: 4px;
  padding-right: 8px;
  width: 100%;
}

@media(max-width: 599px) {
  #AutorizacaoEscrituraCriacao {
    padding-right: 0px;
  }
}

/* ---------- BARRA DE SCROLL ---------- */
#AutorizacaoEscrituraCriacao::-webkit-scrollbar {
  width: 5px;
}

#AutorizacaoEscrituraCriacao::-webkit-scrollbar-button {
  padding: 1px;
}

#AutorizacaoEscrituraCriacao::-webkit-scrollbar-thumb {
  background: #CECECE; 
  border-radius: 50px;
}

#AutorizacaoEscrituraCriacao::-webkit-scrollbar-thumb:hover {
  background: #A7A7A7; 
  height: 150px;
} 

@media(max-width: 599px) {
  #AutorizacaoEscrituraCriacao::-webkit-scrollbar {
    width: initial;
  }

  #AutorizacaoEscrituraCriacao::-webkit-scrollbar-button {
    padding: initial;
  }

  #AutorizacaoEscrituraCriacao::-webkit-scrollbar-thumb {
    background: initial;
    border-radius: initial;
  }

  #AutorizacaoEscrituraCriacao::-webkit-scrollbar-thumb:hover {
    background: initial;
    height: initial;
  } 
}

.container {
  max-width: 100%;
  height: calc(100% - 46px);
}

.nav-link {
  text-decoration: none;
}

.btn {
  width: 100px;
}

.divider {
  margin-bottom: 2px;
}

.footer {
  width: 100%;
  position: sticky;
  bottom: 0;
  background: var(--COR_SECUNDARIA)!important;
  box-shadow: #edeff1 0px 1px 4px 0px, #edeff1 0px 1px 3px 0px!important;
}
</style>
