<template>
  <div id="EscrituraCriacaoModal">
    <v-card class="container pa-0">
      <v-card-title v-bind:style="{ 'background-color':COR_PRINCIPAL }"
      class="justify-space-between py-2 px-3">
        <span class="title-page body-1">Criar Escritura</span>
          <v-btn
          @click="store_Proposta.dialogEscrituraCriacao = false"
          icon
          dark
          color="#F2F6F7"
          >
            <v-icon large class="title">mdi-close</v-icon>
          </v-btn>
      </v-card-title>

      <v-card-text class="wrapper pt-1 pb-10">
        <v-container>
          <v-row>
            <v-col class="px-0">
              <v-form 
                ref="form"
                v-model="valid">

                <v-autocomplete
                  v-model="dados.cartorio"
                  background-color="#FFF"
                  :items="array_cartorios"
                  item-value="codigo"
                  item-text="nome"
                  label="Cartório"
                  class="mb-0"
                  filled
                  required
                  outlined
                  dense
                  :rules="cartorioRules">
                  <!-- <template v-slot:append-outer>
                    <v-icon color="primary"
                    >mdi-plus-circle</v-icon>
                  </template> -->
                </v-autocomplete>

                <v-text-field
                  v-model="dados.livro"
                  class="mt-n2"
                  light
                  loader-height="1"
                  background-color="#FFF"
                  label="Livro"
                  placeholder="Livro"
                  filled
                  type="number"
                  required
                  outlined
                  dense
                  :rules="livroRules">
                </v-text-field>

                <v-text-field
                  v-model="dados.pag_inicial"
                  class="mt-n2"
                  light
                  loader-height="1"
                  background-color="#FFF"
                  label="Página inicial"
                  placeholder="Página inicial"
                  filled
                  required
                  type="number"
                  outlined
                  dense
                  :rules="numeroRules"
                ></v-text-field>

                <v-text-field
                  v-model="dados.pag_final"
                  class="mt-n2"
                  light
                  loader-height="1"
                  background-color="#FFF"
                  label="Página final"
                  placeholder="Página final"
                  filled
                  outlined
                  type="number"
                  required
                  dense
                  :rules="numeroRules"
                ></v-text-field>

                <DatePicker
                  v-model="dados.lavratura_dt"
                  label="Data da Lavratura"
                  placeholder="dia/mês/ano"
                  class="mr-0 mt-n2"
                  outlined
                  backgroundColor="#FFF"
                  tabindex="0"
                  dense
                  :rules="escrituraRules"
                  format="DD/MM/YYYY">
                </DatePicker>

                <v-text-field
                  v-model="dados.outorgado"
                  class="mt-n2"
                  light
                  loader-height="1"
                  background-color="#FFF"
                  label="Outorgado"
                  placeholder="Outorgado"
                  filled
                  outlined
                  required
                  readonly
                  dense
                  :rules="outorgadoRules">
                  <template v-slot:append-outer>
                    <v-icon @click="abrirDialogoPessoa()" color="primary"
                    >mdi-magnify</v-icon>
                  </template>
                </v-text-field>

                <v-text-field
                  v-model="dados.cpf_cnpj"
                  v-mask="MASK_CPFCNPJ"
                  class="mt-n2"
                  light
                  loader-height="1"
                  background-color="#FFF"
                  label="CPF/CNPJ"
                  placeholder="CPF/CNPJ"
                  filled
                  readonly
                  outlined
                  required
                  dense
                  :rules="cpfRules">
                </v-text-field>

                <v-text-field
                  v-model="dados.telefone"
                  v-mask="MASK_TELEFONE"
                  class="mt-n2"
                  light
                  readonly
                  outlined
                  loader-height="1"
                  background-color="#FFF"
                  label="Telefone"
                  placeholder="Telefone"
                  filled
                  required
                  dense>
                </v-text-field>

                <v-textarea
                  v-model="dados.observacoes"
                  rows="1"
                  auto-grow
                  counter="255" 
                  background-color="#FFF"
                  label="Observações"
                  placeholder="Observações"
                  outlined
                  maxlength="255"
                  dense
                ></v-textarea>

              </v-form>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-footer absolute color="#F2F6F7" elevation="0" class="d-flex justify-center pa-3">
        <v-btn
        @click="store_Proposta.dialogEscrituraCriacao = false"
        class="mr-4 btn caption font-weight-medium"
        color="primary"
        text
        >
          Cancelar
        </v-btn>

        <v-btn
        :disabled="!valid"
        @click="validate()"
        class="btn white--text caption font-weight-medium"
        color="primary accent-4"
        >
          Salvar
        </v-btn>
      </v-footer>
    </v-card>
    <!-- DIALOGO PESSOA -------------------------------------------------------->
    <v-container v-if="store_Pessoa.dialogPessoa">      
      <v-dialog
        v-model="store_Pessoa.dialogPessoa"
        scrollable
        max-width="500px"
        max-height="600px"
      >
        <v-card>
          <!-- Cabecalho da PESSOA -->
          <v-card-title :style="{ 'background-color':COR_PRINCIPAL }">
            <v-layout nowrap class="mt-n2" style="width:100%; height:28px">
              <div class="pa-0" style="width:100%">
                <p style="color:white; white-space: pre-line; font-size:16px !important;">
                  Busca de Pessoa
                </p>                      
              </div>
              <div class="pa-0 text-center" style="width:40px;">
                <v-btn icon dark v-on:click="store_Pessoa.dialogPessoa = false" class="mt-n2">
                  <v-icon large class="title">mdi-close</v-icon>
                </v-btn>
              </div>
            </v-layout>
          </v-card-title>
          
          <!-- Corpo do PESSOA -->
          <v-card-text class="pa-0" style="height: 500px;">
            <PessoaConsultaMobile
            class="mt-n2"
            :editar_="true"
            />
          </v-card-text>

          <v-divider></v-divider>
          
          <!-- Rodape do PESSOA -->
          <v-card-actions class="d-flex justify-center pa-3" :style="{ 'background-color':COR_SECUNDARIA }">
            <v-btn
              @click="store_Pessoa.dialogPessoa = false; store_Pessoa.acao_abas = false"
              class="mr-4 btn text-none"
              color="primary"
              text
            >
              Cancelar
            </v-btn>
            <v-btn
              class="btn text-none"
              color="primary"
              :disabled="!store_Pessoa.pessoa_selecionado"
              @click="selecionarPessoa(store_Pessoa.pessoa_selecionado)"
            >
              Selecionar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>

    <!-- FIM DIALOGO PESSOA ---------------------------------------------------->
  </div>
</template>

<script>
import store_Proposta from "../store_Proposta";
import store_Pessoa from "../../Pessoas/store_Pessoa";
import store_site from "../../../store/store_site";
import PessoaConsultaMobile from "../../Pessoas/PessoaConsultaMobile.vue"
import { CpfCnpjIsValid, telefoneIsValid } from "../../../services/funcoes";
import { COR_PRINCIPAL, COR_SECUNDARIA, MASK_CPFCNPJ, MASK_TELEFONE } from "../../../services/constantes";
import DatePicker from "../../ComponentesGlobais/Date-Picker/date-picker.vue";

export default {
  name: "EscrituraCriacaoModal",

  props: {
    array_cartorios: Array,
    array_pessoas: Array
  },

  components: {
    DatePicker,
    PessoaConsultaMobile
  },

  data() {
    return {

      store_Proposta      : store_Proposta,
      store_Pessoa        : store_Pessoa,
      store_site          : store_site,
      COR_PRINCIPAL       : COR_PRINCIPAL,
      COR_SECUNDARIA      : COR_SECUNDARIA,

      MASK_CPFCNPJ      : MASK_CPFCNPJ,
      MASK_TELEFONE     : MASK_TELEFONE,
      loading           : false,

      dados: {
        cod_pessoa: null,
        cartorio: null,
        livro: "",
        pag_inicial: "",
        lavratura_dt : null,
        pag_final: "",
        outorgado: "",
        cpf_cnpj: "",
        telefone: "",
        observacoes: "",
      },

      /* CARTORIOS */
      nomes_cartorio: [
         "Cartório 01", "Cartório 02", "Cartório 03" 
      ],

      /* Inputs e Menus Data */
      // lavratura_dt: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      // menuLavratura_dt: false,

      valid: true,
      form: false,

      outorgadoRules: [
        (value) => !!value || "O outorgado é obrigatório",
       
      ],
      livroRules: [
        (value) => !!value || "O livro é obrigatório",
        (value) =>
          (value && value.length >= 3) ||
          "O livro deve ter no mínimo 3 caracteres",
        (value) =>
          (value && value.length <= 60) ||
          "O livro deve ter menos do que 60 caracteres",
      ],
      foneRules: [
        (value) => !!value || "O telefone é obrigatório",
        (value) =>
          (value && telefoneIsValid(value)) || "O telefone não é válido",
      ],
      escrituraRules: [
        (value) => !!value || "A data da lavratura é obrigatório",
      ],
      cpfRules: [
        (value) => !!value || "O CPF é obrigatório",
        (value) => (value && CpfCnpjIsValid(value)) || "O CPF não é válido",
      ],
      numeroRules: [
        (value) => !!value || "O número é obrigatório",
        (value) => /^\d+$/.test(value) || 'O número não é válido',
        (value) =>
          (value && value.length <= 10) ||
          "O número deve ter menos do que 10 caracteres",
      ],
      cartorioRules: [
        (value) => !!value || "O cartório é obrigatório",
      ],
    };
  },

  mounted(){
    if (!!this.store_Proposta.proposta_selecionado.pessoa_venda) {
      this.dados.outorgado = this.store_Proposta.proposta_selecionado.pessoa_venda.pessoa_nome
      this.dados.cod_pessoa = this.store_Proposta.proposta_selecionado.pessoa_venda.cod_pessoa
      this.dados.cpf_cnpj = 
        this.store_Proposta.proposta_selecionado.pessoa_venda.pessoa_cnpj ? 
        this.store_Proposta.proposta_selecionado.pessoa_venda.pessoa_cnpj : 
        this.store_Proposta.proposta_selecionado.pessoa_venda.pessoa_cpf

      this.dados.telefone = 
        this.store_Proposta.proposta_selecionado.pessoa_venda.fone_celular1 ? 
        this.store_Proposta.proposta_selecionado.pessoa_venda.fone_celular1 : 
        this.store_Proposta.proposta_selecionado.pessoa_venda.fone_residencial
      
    }
  },
  methods: {
    selecionarPessoa(elem) {
        // console.log("🚀 ~ file: EscrituraCriacaoModal.vue:359 ~ selecionarPessoa ~ elem:", elem)
        this.dados.outorgado = elem.pessoa_nome
        this.dados.cod_pessoa = elem.cod_pessoa
        this.dados.cpf_cnpj = elem.pessoa_cpf ? elem.pessoa_cpf : elem.pessoa_cnpj
        this.dados.telefone = elem.fone_celular1 ?elem.fone_celular1 : elem.fone_residencial
        // console.log("🚀 ~ file: EscrituraCriacaoModal.vue:363 ~ selecionarPessoa ~ this.dados:", this.dados)
     
      // this.store_Pessoa.dialogConjugeExcluir  = false
      this.store_Pessoa.dialogPessoa         = false
    },

    async abrirDialogoPessoa(){
      this.store_Pessoa.dialogPessoa = true
    },
    async validate () {
      this.store_Proposta.docEscritura = 1;
      const lb_valido = this.$refs.form.validate();

      if (lb_valido){
        
        var lo_JSON = {
          cod_empreendvenda                : Number(this.$route.params.cod_empreendvenda),
          cod_empreendvenda_escr_cart     : this.dados.cartorio,
          empreendvenda_escr_livro        : this.dados.livro,
          empreendvenda_escr_folha        : this.dados.pag_inicial,
          empreendvenda_escr_folha_final  : this.dados.pag_final,
          empreendvenda_escr_data         : this.dados.lavratura_dt,
          cod_empreendvenda_escr_outorg   : this.dados.cod_pessoa,
          empreendvenda_escr_obs          : this.dados.observacoes
        };
        console.log("🚀 ~ file: EscrituraCriacaoModal.vue:389 ~ validate ~ lo_JSON:", lo_JSON)
        // return
      
        var ls_Res;
        this.loading = true;
       
        ls_Res = await this.store_Proposta.EscrituraPut(lo_JSON);

        if (ls_Res.result && ls_Res.result.message && ls_Res.result.message == 'success') {
          this.store_site.alert_cor = "#00A000";
          this.store_site.alert_timeout = 10000;
          this.store_site.alert_msg = ls_Res.result.result.trim();
          this.store_site.alert = true;

          this.store_Proposta.proposta_selecionado.cod_empreendvenda_escr_cart     = this.dados.cartorio,
          this.store_Proposta.proposta_selecionado.empreendvenda_escr_livro        = this.dados.livro,
          this.store_Proposta.proposta_selecionado.empreendvenda_escr_folha        = this.dados.pag_inicial,
          this.store_Proposta.proposta_selecionado.empreendvenda_escr_folha_final  = this.dados.pag_final,
          this.store_Proposta.proposta_selecionado.empreendvenda_escr_data         = this.dados.lavratura_dt,
          this.store_Proposta.proposta_selecionado.cod_empreendvenda_escr_outorg   = this.dados.cod_pessoa,
          this.store_Proposta.proposta_selecionado.empreendvenda_escr_obs          = this.dados.observacoes
          this.store_Proposta.proposta_selecionado.pess_escr = []
          this.store_Proposta.proposta_selecionado.pess_escr.push(
            { cod_pessoa : this.dados.cod_pessoa, pessoa_nome : this.dados.outorgado })

          
        } else {
          this.store_site.alert_cor = "#A00000";
          this.store_site.alert_timeout = 20000;
          this.store_site.alert_msg = ls_Res.errors.trim();
          this.store_site.alert = true;
        }
        this.loading = false;
        this.store_Proposta.dialogEscrituraCriacao = false
      }
    },
  },
};
</script>

<style scoped>
.container {
  background: #F2F6F7 !important;
}

.title-page {
  font-family: "Open Sans", sans-serif!important;
  color: #FFFFFF;
  letter-spacing: 0px;
}

.wrapper {
  height: 460px;
  overflow-y: auto;
}

/* ---------- BARRA DE SCROLL ---------- */
.wrapper::-webkit-scrollbar {
  width: 5px;
}

.wrapper::-webkit-scrollbar-button {
  padding: 1px;
}

.wrapper::-webkit-scrollbar-thumb {
  background: #CECECE; 
  border-radius: 50px;
  height: 10px!important;
}

.wrapper::-webkit-scrollbar-thumb:hover {
  background: #A7A7A7; 
  height: 150px;
} 

.btn {
  width: 100px;
}
</style>