<template>
  <div id="AutorizacaoEscrituraRemontagem">

    <!-- TÍTULO DA PÁGINA -->
    <v-toolbar-title
      id="topo"
      :style="{ 'color':COR_SUBTITULO }"
      class="body-1 font-primary mt-2 ml-2 mb-4 d-flex justify-space-between">
      <p
        class="font-primary font-weight-bold font-weight-medium body-1 mt-2"
        :style="{ 'color':COR_SUBTITULO }">
        Autorização de Escritura
      <span class="separador">│</span> {{ dados.numero_autorizacao_sistema }}
      </p>
      <!-- <div v-if="store_Proposta.acao == 'C'">
        <v-btn 
          @click="store_Proposta.acao = 'E';"
          class="btn mr-2" elevation="0">
          <v-icon class="btn-icon mr-1" color="green accent-4">mdi-pencil-outline</v-icon>
          <span class="text-none font-weight-medium" :style="{ 'color':COR_SUBTITULO }">Editar</span>
        </v-btn>
        <v-btn 
          @click="dialog_excluir = true;"
          class="btn" elevation="0">
          <v-icon class="btn-icon mr-1" color="red accent-4">mdi-delete-outline</v-icon>
          <span class="text-none font-weight-medium" :style="{ 'color':COR_SUBTITULO }">Deletar</span>
        </v-btn>
      </div> -->
    </v-toolbar-title>
    <!-- FIM TÍTULO DA PÁGINA -->

    <!-------- FORM REMONTAR ----------------------------->
    <v-tabs
      id="tabs"
      v-model="tab"
      background-color="transparent"
      slider-size="2"
      slider-top="10"
      tabindex="-1"
      class="mt-n3"
      active-class="active">
    
      <v-tab
        id="tab"
        class="text-white title-page text-none mt-3 ml-10 "
        :style="{ 'background-color': tab == 0 ? '#FFFFFF' : 'transparent', 'height': '40px' }"
        tabindex="-1"> 
        <p
          class="font-primary body-1 mt-2"
          :style="{ 'color': tab == 0 ? COR_SUBTITULO : '#A0A0A0', 'font-weight': tab == 0 ? 'medium' : 'thin' }">
          Dados Básicos
        </p>
      </v-tab>
      <v-tab
        id="tab"
        class="text-white title-page text-none mt-3 ml-4 "
        :style="{ 'background-color': tab == 1 ? '#FFFFFF' : 'transparent', 'height': '40px' }"
        tabindex="-1"> 
        <p
          class="font-primary body-1 mt-2"
          :style="{ 'color': tab == 1 ? COR_SUBTITULO : '#A0A0A0', 'font-weight': tab == 0 ? 'medium' : 'thin' }">
          Documento
        </p>
      </v-tab>
    </v-tabs>

    <div v-if="tab == 0">
      <v-container class="mx-0 ml-2 pa-0 ">
        <v-toolbar-title
          id="topo"
          :style="{ 'color':COR_SUBTITULO }"
          class="body-1 font-primary mt-2 ml-2 mb-4 d-flex justify-space-between">
          <p
            class="font-primary font-weight-bold font-weight-medium body-1 mt-2"
            :style="{ 'color':COR_SUBTITULO }">
        
          <!-- <span class="separador"></span>  -->
          </p>
          <div v-if="store_Proposta.acao == 'C'">
            <v-btn 
              @click="store_Proposta.acao = 'E';"
              class="btn mr-2" elevation="0">
              <v-icon class="btn-icon mr-1" color="green accent-4">mdi-pencil-outline</v-icon>
              <span class="text-none font-weight-medium" :style="{ 'color':COR_SUBTITULO }">Editar</span>
            </v-btn>
            <v-btn 
              @click="dialog_excluir = true;"
              class="btn" elevation="0">
              <v-icon class="btn-icon mr-1" color="red accent-4">mdi-delete-outline</v-icon>
              <span class="text-none font-weight-medium" :style="{ 'color':COR_SUBTITULO }">Deletar</span>
            </v-btn>
          </div>
        </v-toolbar-title>
        <v-form
          id="form"
          v-model="valid"
          ref="form"
          class="mx-1">
          <v-row>
            <v-col cols="12" sm="4" class="py-0 pt-2 px-2">
              <v-text-field
              v-model="dados.numero_autorizacao"
              class="mb-0"
              light
              loader-height="1"
              background-color="#FFF"
              :readonly="store_Proposta.acao == 'C'"
              label="Número da autorização"
              placeholder="Número da autorização"
              filled
              outlined
              required
              dense
              :rules="numeroRules"
              ></v-text-field>
            </v-col>
    
            <v-col cols="12" sm="8" class="py-0 pt-2 px-2">
              <v-text-field
                v-model="dados.titular"
                light
                loader-height="1"
                background-color="#FFF"
                label="Titular"
                placeholder="Titular"
                outlined
                required
                readonly
                dense>
                <template v-slot:append-outer >
                  <v-icon @click="abreDialogoPessoa()" color="primary" :disabled="store_Proposta.acao == 'C'"
                  >mdi-magnify</v-icon>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
    
          <v-row>
            <v-col sm="4" class=" pt-0 px-2">
              <DatePicker
                v-model="store_Proposta.proposta_selecionado.empreendvenda_aut_data"
                label="Data da Lavratura"
                placeholder="dia/mês/ano"
                class="mr-0 "
                outlined
                backgroundColor="#FFF"
                tabindex="0"
                :readonly="store_Proposta.acao == 'C'"
                dense
                format="DD/MM/YYYY">
              </DatePicker>
            </v-col>
            <!-- FIM TERCEIRA COLUNA / DATA ------------------------>
    
            <v-col cols="12" sm="8"  class="py-0  px-2">
              <v-textarea
                v-model="dados.observacoes"
                rows="1"
                auto-grow
                counter="500" 
                background-color="#FFF"
                label="Observações"
                placeholder="Observações"
                outlined
                maxlength="500"
                :readonly="store_Proposta.acao == 'C'"
                dense
                ></v-textarea>
            </v-col>
          </v-row>
    
          <!-- <v-row>
            <v-col cols="12" sm="12"  class="py-0  px-2">
    
              <EditorDocumento
                :ACAO="ACAO"
                :ARQUIVO="ARQUIVO"
                :TIPO="TIPO"
                :TIPO_VISUALIZACAO="TIPO_VISUALIZACAO"
                :TITULO="TITULO"
                :USER_ADDRESS="USER_ADDRESS"
                :URL_API="URL_API"
                :CAMINHO="CAMINHO"
              />
              </v-col>
          </v-row>        -->
    
        </v-form>

      </v-container>
      <v-divider class="divider mt-3"></v-divider>
      <v-footer
        color="transparent"
        v-if="store_Proposta.acao !== 'C'"
        elevation="0"
        class="footer justify-center pt-3">
          <v-btn
            id="btn_cancelar"
            ref="btn_cancelar"
            class="mr-4 caption font-weight-medium"
            color="primary"
            text
            @click="store_Proposta.acao = 'C'">
            Cancelar
          </v-btn>
          <v-btn
            :disabled="!valid"
            :loading="loading"
            @click="validate()"
            class="btn caption font-weight-medium white--text"
            color="primary accent-4">
            Salvar
          </v-btn>
        </v-footer>
    </div>
    <!-- FIM FORM REMONTAR -->    

    <!-- CONTAINER EDITOR -------------------------------------------------------->
    <div v-if="tab == 1">
      <v-container class="mx-0 pa-0 container" v-if="store_Proposta.acao == 'C'">
        <v-card elevation="0" class="card rounded-lg mx-0 px-0">
          <template>
            <v-toolbar
            flat
            class="rounded-lg d-flex justify-end">
              <v-btn 
                @click="dialog_modelo = true"
                class="mr-4 pr-6" elevation="0">
                <v-icon
                  class="btn-icon mr-1"
                  :style="{ 'color':COR_SUBTITULO }">mdi-sync-circle</v-icon>
                <span
                  class="caption font-weight-medium"
                  :style="{ 'color':COR_SUBTITULO }">
                  Remontar Documento
                </span>
              </v-btn>
  
              <v-btn
                v-if="store_Proposta.proposta_selecionado.doc_aut.length > 0"
                @click="AbrirDocumentoEditor()"
                class="pr-6"
                elevation="0">
                <v-icon
                  class="btn-icon mr-1"
                  :style="{ 'color':COR_SUBTITULO }">mdi-pencil</v-icon>
                <span
                  class="caption font-weight-medium"
                  :style="{ 'color':COR_SUBTITULO }">
                  Editar Documento
                </span>
              </v-btn>
  
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
  
          <!-- EDITOR -------------------------------------------------------->
          <v-card-text class="pt-0" v-if="ARQUIVO && store_Proposta.proposta_selecionado.doc_aut.length > 0">
            <!-- {{ ARQUIVO }} -->
            <div style="background: #FFFFFF; width: 100%; ">
  
              <EditorDocumento
                :ACAO="ACAO"
                :ARQUIVO="ARQUIVO"
                :TIPO="TIPO"
                :TIPO_VISUALIZACAO="TIPO_VISUALIZACAO"
                :TITULO="TITULO"
                :USER_ADDRESS="USER_ADDRESS"
                :URL_API="URL_API"
                :CAMINHO="CAMINHO"
              />
  
              <!-- <iframe frameBorder="0" width='100%' height='1000' src="https://docs.google.com/document/d/1KdFYg7C3qIAZKW-qnmLfpHyll6U7JUPqt0yP6G63pUM/edit#heading=h.6jynaot9cbnq/pub?embedded=true"></iframe>-->
              <!-- https://codepen.io/Eazymov/full/MEzGYv/ -->
              <!-- https://openbase.com/js/vue2-editor -->
              <!-- https://github.surmon.me/vue-quill-editor/ -->
              <!-- https://openbase.com/js/@toast-ui/vue-editor#-examples -->
              <!-- http://hifarer.github.io/vueditor/ -->
              <!-- https://donotebase.github.io/quasar-tiptap/examples/all -->
              <!-- https://openbase.com/js/mavon-editor -->
              </div>
          </v-card-text>
          <!-- FIM EDITOR -------------------------------------------------------->
        </v-card>
      </v-container>
    </div>
    <!-- FIM CONTAINER EDITOR -------------------------------------------------------->

    <v-divider class="divider mt-3"></v-divider>

    <v-alert
      :value="alert"
      v-on:click="fecha_alert()"
      color="red"
      dark
      icon="mdi-home"
      transition="scale-transition"
      elevation="1"
      type="warning"
      dense
    >
      {{ alert_msg }}
    </v-alert>

    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snack = false">Close</v-btn>
      </template>
    </v-snackbar>    

    <!-- DIALOGO PESSOA -------------------------------------------------------->
    <v-container v-if="store_Pessoa.dialogPessoa">      
      <v-dialog
        v-model="store_Pessoa.dialogPessoa"
        scrollable
        max-width="500px"
        max-height="600px"
      >
        <v-card>
          <!-- Cabecalho da PESSOA -->
          <v-card-title :style="{ 'background-color':COR_PRINCIPAL }">
            <v-layout nowrap class="mt-n2" style="width:100%; height:28px">
              <div class="pa-0" style="width:100%">
                <p style="color:white; white-space: pre-line; font-size:16px !important;">
                  Busca de Pessoa
                </p>                      
              </div>
              <div class="pa-0 text-center" style="width:40px;">
                <v-btn icon dark v-on:click="store_Pessoa.dialogPessoa = false" class="mt-n2">
                  <v-icon large class="title">mdi-close</v-icon>
                </v-btn>
              </div>
            </v-layout>
          </v-card-title>
          
          <!-- Corpo do PESSOA -->
          <v-card-text class="pa-0" style="height: 500px;">
            <PessoaConsultaMobile
              class="mt-n2"
              :editar_="true"
            />
          </v-card-text>

          <v-divider></v-divider>
          
          <!-- Rodape do PESSOA -->
          <v-card-actions class="d-flex justify-center pa-3" :style="{ 'background-color':COR_SECUNDARIA }">
            <v-btn
              @click="store_Pessoa.dialogPessoa = false; store_Pessoa.acao_abas = false"
              class="mr-4 btn text-none"
              color="primary"
              text
            >
              Cancelar
            </v-btn>
            <v-btn
              class="btn text-none"
              color="primary"
              :disabled="!store_Pessoa.pessoa_selecionado"
              @click="selecionarPessoa(store_Pessoa.pessoa_selecionado)"
            >
              Selecionar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <!-- FIM DIALOGO PESSOA ---------------------------------------------------->

    <!--------- DIALOG EXCLUIR ESCRITURA ------------------------------->
    <v-container v-if="dialog_excluir">
      <v-dialog v-model="dialog_excluir" transition="dialog-bottom-transition" persistent max-width="410" class="pa-0">
        <v-divider></v-divider>
  
        <v-card elevation="0" class="">
          <v-card-title class="text-h6" style="word-break: keep-all">
            Tem certeza que deseja EXCLUIR esta escritura?
          </v-card-title>
          <v-card-text>
            <!-- <span style="font-size: 15px; font-weight:600">{{store_Pessoa.pessoa_selecionado.pessoa_nome}}</span><br> -->
            <!-- Tipo: {{tipo_Aux}}<br> -->
            <!-- Data cadastro: {{cadastro_dt_Aux}}<br> -->
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-spacer></v-spacer>
            <v-btn id="btn_cancelar" ref="btn_cancelar" class="mr-4" color="primary" text @click="dialog_excluir = false">
              Cancelar
            </v-btn>
  
            <v-btn class="btn white--text" color="primary accent-4" :loading="loading" @click="excluir()">
              Excluir
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <!--------- FIM DIALOG EXCLUIR ESCRITURA ------------------------------->

    <!--------- DIALOG modelo ------------------------------->
    <v-container v-if="dialog_modelo">
      <v-dialog v-model="dialog_modelo"  max-width="440px">
      <v-card v-bind:style="{ 'background-color':COR_SECUNDARIA }" class="container pa-0">
        <v-card-title v-bind:style="{ 'background-color':COR_PRINCIPAL }" class="justify-space-between py-2 px-3">
          <span class="text-white title-page">Novo Documento</span>
          <v-btn @click="dialog_modelo = false" icon dark color="#F2F6F7">
            <v-icon large class="title">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="wrapper pt-1 px-0 pb-13">
          <v-container class="px-7">
            <v-row>
              <v-col class="pb-0 px-0">
                <v-form v-model="valid" ref="formModelo">
                  <!-- <pre>{{ array_modelos }}</pre> -->
                  <v-autocomplete 
                    label="Modelo" 
                    v-model="modelo_escolhido" 
                    filled 
                    light 
                    dense 
                    return-object
                    outlined
                    background-color="#FFF" 
                    :items="array_modelos" 
                    required
                    :rules="modeloRules"
                    item-value="cod_modelo_documento"
                    item-text="titulo">
                  </v-autocomplete>

                </v-form>
              </v-col>
            </v-row>
          </v-container>

          <v-divider class="mx-4 mb-2"></v-divider>

        </v-card-text>

        <v-footer v-bind:style="{ 'background-color':COR_SECUNDARIA }" absolute elevation="0" class="d-flex justify-center pa-3">
          <v-btn @click="dialog_modelo = false" class="mr-4 btn caption font-weight-medium" color="primary" text>
            Cancelar
          </v-btn>

          <v-btn 
            :disabled="!valid" 
            @click="montarModelo()" 
            class="btn white--text caption font-weight-medium" 
            :loading="loading" 
            color="primary accent-4">
            Salvar
          </v-btn>
        </v-footer>
      </v-card>
    </v-dialog>
    </v-container>
    <!--------- FIM DIALOG modelo ------------------------------->

    <!-- btn de rolar ao topo -->
    <v-btn
      :color="COR_PRINCIPAL"
      id="myBtn"
      @click="topFunction()"><v-icon
      color="white"
      size="x-large">mdi-chevron-up</v-icon>
    </v-btn>

  </div>
</template>

<script>

      // Seta os atributos para o servidor pegar como referencia e conseguir "enxergar" o que fazer
      // const ls_empresa    = this.store_site.cod_empresa.toString().padStart(6, '0');
      // const ls_empreend   = this.$route.params.cod_empreendimento.toString().padStart(6, '0');
      // const URL_API       = store_usuario.user.url_api;
      // this.USER_ADDRESS   = `empresa_${ls_empresa}`;
      // this.NOME_ARQUIVO   = cod_arquivo.toString().padStart(6, '0');
      // this.ARQUIVO        = `empreend_modelo_${this.TIPO}_${ls_empresa}_${ls_empreend}_${this.NOME_ARQUIVO}.docx`;
      // this.CAMINHO        = `empreend_modelo_/${this.TIPO}`;
      // this.TITULO         = titulo;

import store_Proposta from "../store_Proposta";
import store_Pessoa from "../../Pessoas/store_Pessoa";
import store_site from "../../../store/store_site";
import store_usuario from "../../../store/store_usuario";
import PessoaConsultaMobile from "../../Pessoas/PessoaConsultaMobile.vue"
import { COR_PRINCIPAL, COR_SECUNDARIA, COR_SUBTITULO } from "../../../services/constantes";
import DatePicker from "../../ComponentesGlobais/Date-Picker/date-picker.vue";
import { API } from "../../../services/API"
import EditorDocumento from "../../ComponentesGlobais/EditorDocumentos/EditorDocumento.vue"
import moment from 'moment';

export default {
  name: "AutorizacaoEscrituraRemontagem",

  components: {
    DatePicker,
    PessoaConsultaMobile,
    EditorDocumento
  },

  data() {
    return {

      store_Proposta  : store_Proposta,
      store_Pessoa    : store_Pessoa,
      store_site      : store_site,
      store_usuario   : store_usuario,

      COR_SUBTITULO      : COR_SUBTITULO, 
      COR_PRINCIPAL      : COR_PRINCIPAL,
      COR_SECUNDARIA     : COR_SECUNDARIA, 
      API                : API,
      conteudo_documento : false,
      isVisible          : false,
      tab                : 0,

      ACAO              : 'ABRIR_DOCUMENTO',
      ARQUIVO           : null,
      USER_ADDRESS      : 'empresa_' + store_site.cod_empresa.toString().padStart(6, '0'),
      TIPO              : 'autorizacao',
      TIPO_VISUALIZACAO : 'embedded',
      URL_API           : store_usuario.user.url_api,
      //URL_API           : 'http://192.168.8.100:30001',
      CAMINHO           : `empreend_venda/autorizacao`,
      TITULO            : '',
      
      valid             : true,
      loading           : false,
      alert             : false,
      alert_msg         : "",
      dialog_excluir    : null,
      modelo_escolhido  : null,
      dialog_modelo     : false,
      array_modelos     : [],

      dados: {
        numero_autorizacao_sistema: "0002/2015",
        numero_autorizacao: null,
        titular: null,
        modelo: null,
        observacoes: "",
      },

    /* Titular */
      nomes_titular: [
         "Fernando Garcia", "Bruno Duarte", "João Feliciano" 
      ],

    /* Inputs e Menus Data */
      autorizacao_dt: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menuAutorizacao_dt: false,

    /* SELECT AUTORIZACAO */
      modelo_autorizacao: [
        "AUTORIZAÇÃO - Santa Lucia (modelo 1)",
      ],

    /* Formulário Reprovação */
      snack: false,
      snackColor: "",
      snackText: "",
      form: false,

      numeroRules: [
        (value) => !!value || "O número é obrigatório",
        (value) => /^\d+$/.test(value) || 'O número não é válido',
        (value) =>
          (value && value.length <= 10) ||
          "O número deve ter menos do que 10 caracteres",
      ],
      titularRules: [
        (value) => !!value || "O titular é obrigatório",
      ],
      modeloRules: [
        (value) => !!value || "O modelo é obrigatório",
      ],
    };
  },


  mounted(){
    this.modelosGet()
    if (!!this.store_Proposta.proposta_selecionado.empreendvenda_aut_prenumero) {
      this.dados.numero_autorizacao_sistema = this.store_Proposta.proposta_selecionado.empreendvenda_autorizacao
      this.dados.numero_autorizacao = this.store_Proposta.proposta_selecionado.empreendvenda_aut_prenumero
      this.dados.titular = this.store_Proposta.proposta_selecionado?.pess_aut?.pessoa_nome
      this.dados.cod_pessoa = this.store_Proposta.proposta_selecionado?.pess_aut?.cod_pessoa
      this.dados.observacoes = this.store_Proposta.proposta_selecionado.empreendvenda_aut_obs
    }
    if(this.store_Proposta.proposta_selecionado.doc_aut.length > 0){
      this.TITULO = this.store_Proposta.proposta_selecionado.doc_aut[0].filename
      this.ARQUIVO = this.store_Proposta.proposta_selecionado.doc_aut[0].filename
    }
  },

  methods: {
    topFunction() {
      // Posiciona o Scrool no começo da tabela 
      const elemento = document.getElementById('topo');
      elemento.scrollIntoView({
        behavior: "smooth" // Define o comportamento de rolagem suave
      });
    },

      // Abre Modelo Word e abre o Editor para edição
      AbrirDocumentoEditor() {

        const lo_Query = {  ACAO          : 'ABRIR_DOCUMENTO',
                            ARQUIVO       : this.ARQUIVO,
                            USER_ADDRESS  : this.USER_ADDRESS,
                            TIPO          : this.TIPO,
                            URL_API       : this.URL_API,
                            CAMINHO       : this.CAMINHO,
                            TITULO        : this.TITULO,                                                     
                          };
        // console.log('lo_Query:: ', lo_Query);
        const routeData = this.$router.resolve({ name   : 'EditorDocumento',
                                                query  : lo_Query });
        // Abre o editor em nova tela
        window.open(routeData.href, '_blank');
      },

    async montarModelo(){
      const lb_valido = this.$refs.formModelo.validate();

      if (lb_valido){ 
        var lo_JSON = {
          cod_empreendvenda    : this.$route.params.cod_empreendvenda,
          tipo_modelo          : this.modelo_escolhido.tipo,
          cod_empresa          : this.modelo_escolhido.cod_empresa,
          filename             : this.modelo_escolhido.filename
        };
      
        var ls_Res;
        this.loading = true;
        let cod_editor_documento = null;
        if (this.store_Proposta.proposta_selecionado.doc_aut.length > 0) {
          cod_editor_documento = this.store_Proposta.proposta_selecionado.doc_aut[0].cod_editor_documento;
        }
        else 
        {
          ls_Res = await this.store_Proposta.ModeloPost(lo_JSON);
          if (ls_Res.result.message != 'success'){
            this.store_site.alert_cor = "#A00000";
            this.store_site.alert_timeout = 20000;
            this.store_site.alert_msg = ls_Res.errors.trim();
            this.store_site.alert = true;
            this.dialog_modelo = false

            var lo_params = {
              cod_empreendvenda: this.$route.params.cod_empreendvenda
            };
            if (lo_params.cod_empreendvenda) {
              this.store_Proposta.proposta_selecionado = {};
              this.store_Proposta.acao = "C";
              var lo_Res = await this.store_Proposta.PropostaGet(lo_params);
              console.log("🚀 ~ file: AutorizacaoEscrituraRemontagem.vue:548 ~ montarModelo ~ lo_Res:", lo_Res)
            }

            if (!!lo_Res) {
              this.store_Proposta.proposta_selecionado = { ...lo_Res };
            }
          }
          
            this.store_Proposta.proposta_selecionado.doc_aut = []
            this.store_Proposta.proposta_selecionado.doc_aut.push(
              ls_Res.result.data
            )
          
          cod_editor_documento = ls_Res.result.data.cod_editor_documento;
        }

        //neste trecho estou fazendo a copia do arquivo modelo para a pasta correspondente
        let empresa = String(this.store_site.cod_empresa).padStart(6, 0)
        const param_modelo_arquivo = `editor_documentos/empresa_${empresa}/empreend_modelo/${this.modelo_escolhido.tipo}/${this.modelo_escolhido.filename}`
        const param_output_caminho = `editor_documentos/empresa_${empresa}/empreend_venda/${this.modelo_escolhido.tipo}`
        const param_output_arquivo = `empreend_venda_${this.modelo_escolhido.tipo}_${empresa}_${String(this.$route.params.cod_empreendvenda).padStart(6, 0)}_${String(cod_editor_documento).padStart(6, 0)}.docx`
        
        const lo_params_docx = {
          modelo_arquivo : param_modelo_arquivo,
          output_caminho : param_output_caminho,
          output_arquivo : param_output_arquivo
        }
        // cria uma cópia do modelo para o lugar correto e com o nome correto
        const ls_ResDOCX =  await API.post('/mescla_docx', JSON.stringify(lo_params_docx))
        
        // nomeando as variaveis para serem enviadas ao componente
        this.TITULO = param_output_arquivo
        this.ARQUIVO = param_output_arquivo
        this.store_Proposta.proposta_selecionado.doc_aut[0].filename = param_output_arquivo

        let json_update = {
          cod_empreendvenda    : Number(this.$route.params.cod_empreendvenda),
          filename             : param_output_arquivo,
          cod_editor_documento : cod_editor_documento ? cod_editor_documento : this.store_Proposta.proposta_selecionado.doc_aut[0].cod_editor_documento,
          conteudo             : ls_ResDOCX.data.result.data,
          ultima_alteracao_dt  : moment().format()
        }

        // atualiza o banco com o conteudo correto e a ultima data de alteração
        ls_Res = await this.store_Proposta.ModeloPut(json_update);
        // fim da cópia do arquivo

        if (ls_Res.result && ls_Res.result.message && ls_Res.result.message == 'success') {
          this.store_site.alert_cor = "#00A000";
          this.store_site.alert_timeout = 10000;
          this.store_site.alert_msg = ls_Res.result.result.trim();
          this.store_site.alert = true;
          
        } else {
          this.store_site.alert_cor = "#A00000";
          this.store_site.alert_timeout = 20000;
          this.store_site.alert_msg = ls_Res.errors.trim();
          this.store_site.alert = true;
        }
        this.loading = false;
        this.dialog_modelo = false
      }
    },

    async modelosGet(){
      const p_param = { tipo : 'autorizacao', cod_empreendimento: this.store_Proposta.proposta_selecionado.cod_empreendimento } 
      const resposta = await API.get("modelo_documento", {params: p_param});
      if (resposta.data.result == "Não possui dados") {
        this.array_modelos = []
      }
      else{
        this.array_modelos =  resposta.data.result
      }
    },

    async excluir() {
      var lo_JSON = {
        cod_empreendvenda          : Number(this.$route.params.cod_empreendvenda),
        empreendvenda_aut_data     : null,
        empreendvenda_aut_numero   : null,
        empreendvenda_aut_obs      : null,
        empreendvenda_aut_prenumero: null,
        empreendvenda_aut_texto    : null,
        empreendvenda_aut_titular  : null,
        empreendvenda_autorizacao  : null,
        dados_docx                 : store_Proposta.proposta_selecionado.doc_aut

      };
      console.log("🚀 ~ file: AutorizacaoEscrituraRemontagem.vue:626 ~ excluir ~ lo_JSON:", store_Proposta.proposta_selecionado.doc_aut)
      // return
    
      var ls_Res;
      this.loading = true;
      
      ls_Res = await this.store_Proposta.AutorizacaoDelete(lo_JSON);

      if (ls_Res.result && ls_Res.result.message && ls_Res.result.message == 'success') {
        this.store_site.alert_cor = "#00A000";
        this.store_site.alert_timeout = 10000;
        this.store_site.alert_msg = ls_Res.result.result.trim();
        this.store_site.alert = true;

        this.store_Proposta.proposta_selecionado.empreendvenda_aut_data      = null,
        this.store_Proposta.proposta_selecionado.empreendvenda_aut_numero    = null,
        this.store_Proposta.proposta_selecionado.empreendvenda_aut_obs       = null,
        this.store_Proposta.proposta_selecionado.empreendvenda_aut_prenumero = null,
        this.store_Proposta.proposta_selecionado.empreendvenda_aut_texto     = null,
        this.store_Proposta.proposta_selecionado.empreendvenda_aut_titular   = null,
        this.store_Proposta.proposta_selecionado.empreendvenda_autorizacao   = null

        this.store_Proposta.proposta_selecionado.doc_aut = []
        
      } else {
        this.store_site.alert_cor = "#A00000";
        this.store_site.alert_timeout = 20000;
        this.store_site.alert_msg = ls_Res.errors.trim();
        this.store_site.alert = true;
      }
      this.loading = false;
      this.dialog_excluir = false
      this.store_Proposta.acao = 'C'
    },

    async abreDialogoPessoa(){
      if (this.store_Proposta.acao !== 'C') {
        this.store_Pessoa.dialogPessoa = true
      }
    },

    selecionarPessoa(elem) {
      // este método seleciona a pessoa
      this.dados.titular = elem.pessoa_nome
      this.dados.cod_pessoa = elem.cod_pessoa
     
      this.store_Pessoa.dialogPessoa = false
    },

    fecha_alert() {
      this.alert = false;
    },

    async validate () {
      this.store_Proposta.docAutorizacaoEscritura = 1;

      const lb_valido = this.$refs.form.validate();

      if (lb_valido){ 
        
        var lo_JSON = {
          cod_empreendvenda               : Number(this.$route.params.cod_empreendvenda),
          empreendvenda_aut_prenumero     : this.dados.numero_autorizacao,
          empreendvenda_aut_titular       : this.dados.cod_pessoa,
          empreendvenda_aut_data          : this.store_Proposta.proposta_selecionado.empreendvenda_aut_data,
          empreendvenda_aut_texto         : this.dados.modelo,
          empreendvenda_aut_obs           : this.dados.observacoes,
          cod_empreendimento              : this.store_Proposta.proposta_selecionado.cod_empreendimento,
          numero_autorizacao_sistema      : this.dados.numero_autorizacao_sistema
        };
      
        var ls_Res;
        this.loading = true;
       
        ls_Res = await this.store_Proposta.AutorizacaoPut(lo_JSON);

        this.loading = false;
        if (ls_Res.result && ls_Res.result.message && ls_Res.result.message == 'success') {
          this.store_site.alert_cor = "#00A000";
          this.store_site.alert_timeout = 10000;
          this.store_site.alert_msg = ls_Res.result.result.trim();
          this.store_site.alert = true;

          this.store_Proposta.proposta_selecionado.empreendvenda_aut_prenumero = this.dados.numero_autorizacao
          this.store_Proposta.proposta_selecionado.empreendvenda_aut_titular   = this.dados.titular
          // this.store_Proposta.proposta_selecionado.empreendvenda_aut_data      = this.dados.lavratura_dt
          this.store_Proposta.proposta_selecionado.empreendvenda_aut_texto     = this.dados.modelo
          this.store_Proposta.proposta_selecionado.empreendvenda_aut_obs       = this.dados.observacoes
          this.store_Proposta.proposta_selecionado.empreendvenda_autorizacao   = ls_Res.result.data[1][0].empreendvenda_autorizacao

          this.store_Proposta.proposta_selecionado.pess_aut = {}
          this.store_Proposta.proposta_selecionado.pess_aut = { cod_pessoa : this.dados.cod_pessoa, pessoa_nome : this.dados.titular }
          
        } else {
          this.store_site.alert_cor = "#A00000";
          this.store_site.alert_timeout = 20000;
          this.store_site.alert_msg = ls_Res.errors.trim();
          this.store_site.alert = true;
        }
        this.store_Proposta.dialogAutorizacaoEscrituraCriacao = false
        this.store_Proposta.acao = 'C'
      }
    },
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.name === "xs";
    },
  },
};
</script>

<style scoped>
#AutorizacaoEscrituraRemontagem {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  clear: both;
  padding-top: 4px;
  padding-right: 8px;
  width: 100%;
}

.text-white {
  color: #FFFFFF;
  letter-spacing: 0px;
}

.title-page {
  font-family: "Open Sans", sans-serif !important;
  letter-spacing: 0px;
  font-size: 18px;
}

@media(max-width: 599px) {
  #AutorizacaoEscrituraRemontagem {
    padding-right: 0px;
  }
}

.separador {
  color: #505050!important;
  opacity: 0.6;
}

/* ---------- BARRA DE SCROLL ---------- */
#AutorizacaoEscrituraRemontagem::-webkit-scrollbar {
  width: 5px;
}

#AutorizacaoEscrituraRemontagem::-webkit-scrollbar-button {
  padding: 1px;
}

#AutorizacaoEscrituraRemontagem::-webkit-scrollbar-thumb {
  background: #CECECE; 
  border-radius: 50px;
}

#AutorizacaoEscrituraRemontagem::-webkit-scrollbar-thumb:hover {
  background: #A7A7A7; 
  height: 150px;
} 

@media(max-width: 599px) {
  #AutorizacaoEscrituraRemontagem::-webkit-scrollbar {
    width: initial;
  }

  #AutorizacaoEscrituraRemontagem::-webkit-scrollbar-button {
    padding: initial;
  }

  #AutorizacaoEscrituraRemontagem::-webkit-scrollbar-thumb {
    background: initial;
    border-radius: initial;
  }

  #AutorizacaoEscrituraRemontagem::-webkit-scrollbar-thumb:hover {
    background: initial;
    height: initial;
  } 
}

.container {
  max-width: 100%;
}

.card {
  box-shadow: var(--COR_SOMBRA)!important;
}

.btn {
  width: 100px;
}

.btn-icon {
  font-size: 20px;
}

@media(max-width: 599px) {
  .col-terceira-pf div:nth-child(2) {
    margin-top: -16px;
  }

  .col-terceira-pf div:nth-child(3) {
    margin-top: -12px;
  }
}

.divider {
  margin-bottom: 2px;
}

#myBtn {
  position: fixed;
  border-radius: 50px;
  bottom: 15px;
  right: 0;
  z-index: 99;
  outline: none;
  cursor: pointer;
  opacity: 0.8;
  min-width: 40px;
  width: 45px;
  height: 43px;
  -webkit-animation: fadeInFromNone 1s ease-out;
  -moz-animation: fadeInFromNone 1s ease-out;
  -o-animation: fadeInFromNone 1s ease-out;
  animation: fadeInFromNone 1s ease-out;
}
.footer {
  width: 100%;
  position: sticky;
  bottom: 0;
  background: var(--COR_SECUNDARIA)!important;
  box-shadow: #edeff1 0px 1px 4px 0px, #edeff1 0px 1px 3px 0px!important;
}
</style>
