<template>
  <div id="ContratoCriacaoModal">
    <v-card class="container pa-0">
      <v-card-title v-bind:style="{ 'background-color':COR_PRINCIPAL }"
      class="justify-space-between py-2 px-3">
        <span class="title-page body-1">Criar Contrato</span>
          <v-btn
          @click="store_Proposta.dialogContratoCriacao = false"
          icon
          dark
          color="#F2F6F7"
          >
            <v-icon large class="title">mdi-close</v-icon>
          </v-btn>
      </v-card-title>

      <v-card-text class="wrapper pt-1 pb-10">
        <v-container>
          <v-row>
            <v-col class="px-0">
              <v-form 
              ref="form"
              v-model="valid">

                <v-text-field
                v-model="dados.numero_contrato"
                class="mb-0"
                light
                loader-height="1"
                background-color="#FFF"
                label="Número do contrato"
                placeholder="Número do contrato"
                filled
                required
                outlined
                dense
                :rules="numeroRules"
                ></v-text-field>

                <v-text-field
                  v-model="dados.titular"
                  class="mt-n2"
                  light
                  loader-height="1"
                  background-color="#FFF"
                  label="Titular"
                  placeholder="Titular"
                  filled
                  outlined
                  required
                  readonly
                  dense
                  :rules="titularRules">
                  <template v-slot:append-outer>
                    <v-icon @click="abrirDialogoPessoa()" color="primary"
                    >mdi-magnify</v-icon>
                  </template>
                </v-text-field>

              <DatePicker
                v-model="dados.contrato_dt"
                label="Data do Contrato"
                placeholder="dia/mês/ano"
                class="mr-0 mt-n2"
                outlined
                backgroundColor="#FFF"
                tabindex="0"
                dense
                :rules="dataRules"
                format="DD/MM/YYYY">
              </DatePicker>

              <v-select
                label="Assinado e finalizado"
                v-model="dados.assinado_finalizado"
                class="mt-n2"
                filled
                dense
                background-color="#FFF"
                :items="arraySIM_NAO"
                item-text="name"
                outlined
                :rules="assinaturaRules"
              ></v-select>

              <v-textarea
                v-model="dados.observacoes"
                rows="1"
                auto-grow
                class="mt-n2"
                background-color="#FFF"
                label="Observação"
                outlined
                counter="255" 
                placeholder="Observação"
                filled
                dense
              ></v-textarea>

              </v-form>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-footer absolute color="#F2F6F7" elevation="0" class="d-flex justify-center pa-3">
        <v-btn
        @click="store_Proposta.dialogContratoCriacao = false"
        class="mr-4 btn caption font-weight-medium"
        color="primary"
        text
        >
          Cancelar
        </v-btn>

        <v-btn
        :disabled="!valid"
        @click="validate()"
        class="btn white--text caption font-weight-medium"
        color="primary accent-4"
        >
          Salvar
        </v-btn>
      </v-footer>
    </v-card>
        <!-- DIALOGO PESSOA -------------------------------------------------------->
        <v-container v-if="store_Pessoa.dialogPessoa">      
      <v-dialog
        v-model="store_Pessoa.dialogPessoa"
        scrollable
        max-width="500px"
        max-height="600px"
      >
        <v-card>
          <!-- Cabecalho da PESSOA -->
          <v-card-title :style="{ 'background-color':COR_PRINCIPAL }">
            <v-layout nowrap class="mt-n2" style="width:100%; height:28px">
              <div class="pa-0" style="width:100%">
                <p style="color:white; white-space: pre-line; font-size:16px !important;">
                  Busca de Pessoa
                </p>                      
              </div>
              <div class="pa-0 text-center" style="width:40px;">
                <v-btn icon dark v-on:click="store_Pessoa.dialogPessoa = false" class="mt-n2">
                  <v-icon large class="title">mdi-close</v-icon>
                </v-btn>
              </div>
            </v-layout>
          </v-card-title>
          
          <!-- Corpo do PESSOA -->
          <v-card-text class="pa-0" style="height: 500px;">
            <PessoaConsultaMobile
              class="mt-n2"
              :editar_="true"
            />
          </v-card-text>

          <v-divider></v-divider>
          
          <!-- Rodape do PESSOA -->
          <v-card-actions class="d-flex justify-center pa-3" :style="{ 'background-color':COR_SECUNDARIA }">
            <v-btn
              @click="store_Pessoa.dialogPessoa = false; store_Pessoa.acao_abas = false"
              class="mr-4 btn text-none"
              color="primary"
              text
            >
              Cancelar
            </v-btn>
            <v-btn
              class="btn text-none"
              color="primary"
              :disabled="!store_Pessoa.pessoa_selecionado"
              @click="selecionarPessoa(store_Pessoa.pessoa_selecionado)"
            >
              Selecionar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <!-- FIM DIALOGO PESSOA ---------------------------------------------------->
  </div>
</template>

<script>
import store_Proposta from "../store_Proposta";
import store_Pessoa from "../../Pessoas/store_Pessoa";
import store_site from "../../../store/store_site";
import { COR_PRINCIPAL, COR_SECUNDARIA } from "../../../services/constantes";
import PessoaConsultaMobile from "../../Pessoas/PessoaConsultaMobile.vue"
import DatePicker from "../../ComponentesGlobais/Date-Picker/date-picker.vue";
import moment from "moment"

export default {
  name: "ContratoCriacaoModal",

  components: {
    PessoaConsultaMobile,
    DatePicker
  },

  data() {
    return {

      store_Proposta    : store_Proposta,
      store_Pessoa      : store_Pessoa,
      store_site        : store_site,
      COR_PRINCIPAL     : COR_PRINCIPAL,
      COR_SECUNDARIA    : COR_SECUNDARIA,

      dados: {
        cod_pessoa: null,
        numero_contrato: null,
        titular: null,
        assinado_finalizado: 'SIM',
        contrato_dt : moment().format(),
        observacoes: "",
      },

      valid        : true,
      form         : false,
      arraySIM_NAO : ['SIM', 'NÃO'],

      numeroRules: [
        (value) => !!value || "O número é obrigatório",
        (value) => /^\d+$/.test(value) || 'O número não é válido',
        (value) =>
          (value && value.length <= 10) ||
          "O número deve ter menos do que 10 caracteres",
      ],
      titularRules: [
        (value) => !!value || "O titular é obrigatório",
      ],
      modeloRules: [
        (value) => !!value || "O modelo é obrigatório",
      ],
      assinaturaRules: [
        (value) => !!value || "A assinatura é obrigatório",
      ],
      dataRules: [
        (value) => !!value || "A data é obrigatória",
      ],
    };
  },

  mounted(){
    if (!!this.store_Proposta.proposta_selecionado.pessoa_venda) {
      this.dados.titular = this.store_Proposta.proposta_selecionado.pessoa_venda.pessoa_nome
      this.dados.cod_pessoa = this.store_Proposta.proposta_selecionado.pessoa_venda.cod_pessoa
    }
  },

  methods: {
    selecionarPessoa(elem) {
      // este método seleciona a pessoa
      this.dados.titular = elem.pessoa_nome
      this.dados.cod_pessoa = elem.cod_pessoa
     
      this.store_Pessoa.dialogPessoa = false
    },

    async abrirDialogoPessoa(){
      this.store_Pessoa.dialogPessoa = true
    },

    async validate () {
      this.store_Proposta.docContrato = 1;

      const lb_valido = this.$refs.form.validate();

      if (lb_valido){
        
        var lo_JSON = {
          cod_empreendvenda               : Number(this.$route.params.cod_empreendvenda),
          empreendvenda_contr_data        : this.dados.contrato_dt,
          empreendvenda_contr_finalizado  : this.dados.assinado_finalizado,
          empreendvenda_contr_obs         : this.dados.observacoes,
          empreendvenda_contr_pessoa      : this.dados.cod_pessoa,
          empreendvenda_contr_prenumero   : this.dados.numero_contrato,
          cod_empreendimento              : this.store_Proposta.proposta_selecionado.cod_empreendimento
        };

        // console.log("🚀 ~ file: ContratoCriacaoModal.vue:283 ~ validate ~ lo_JSON.this.dados:", this.dados)
        // return
      
        var ls_Res;
        this.loading = true;
       
        ls_Res = await this.store_Proposta.ContratoPut(lo_JSON);

        this.loading = false;

        if (ls_Res.result && ls_Res.result.message && ls_Res.result.message == 'success') {
          this.store_site.alert_cor = "#00A000";
          this.store_site.alert_timeout = 10000;
          this.store_site.alert_msg = ls_Res.result.result.trim();
          this.store_site.alert = true;

          this.store_Proposta.proposta_selecionado.empreendvenda_contr_data = this.dados.contrato_dt
          this.store_Proposta.proposta_selecionado.empreendvenda_contr_finalizado = this.dados.assinado_finalizado
          this.store_Proposta.proposta_selecionado.empreendvenda_contr_obs = this.dados.observacoes
          this.store_Proposta.proposta_selecionado.empreendvenda_contr_pessoa = this.dados.cod_pessoa
          this.store_Proposta.proposta_selecionado.empreendvenda_contr_prenumero = this.dados.numero_contrato
          this.store_Proposta.proposta_selecionado.empreendvenda_contrato = ls_Res.result.data[1][0].empreendvenda_contrato


         
          this.store_Proposta.proposta_selecionado.pess_contr = {}
          this.store_Proposta.proposta_selecionado.pess_contr ={ cod_pessoa : this.dados.cod_pessoa, pessoa_nome : this.dados.titular }
          
        } else {
          this.store_site.alert_cor = "#A00000";
          this.store_site.alert_timeout = 20000;
          this.store_site.alert_msg = ls_Res.errors.trim();
          this.store_site.alert = true;
        }
        this.store_Proposta.dialogContratoCriacao = false
      }
    },
  },
};
</script>

<style scoped>
.container {
  background: #F2F6F7 !important;
}

.title-page {
  font-family: "Open Sans", sans-serif!important;
  color: #FFFFFF;
  letter-spacing: 0px;
}

.wrapper {
  height: 460px;
  overflow-y: auto;
}

/* ---------- BARRA DE SCROLL ---------- */
.wrapper::-webkit-scrollbar {
  width: 5px;
}

.wrapper::-webkit-scrollbar-button {
  padding: 1px;
}

.wrapper::-webkit-scrollbar-thumb {
  background: #CECECE; 
  border-radius: 50px;
  height: 10px!important;
}

.wrapper::-webkit-scrollbar-thumb:hover {
  background: #A7A7A7; 
  height: 150px;
} 

.btn {
  width: 100px;
}
</style>