<template>
  <div id="EscrituraRemontagem">

    <!-- TÍTULO DA PÁGINA -->
    <v-toolbar-title v-bind:style="{ 'color':COR_SUBTITULO }" class="body-1 font-primary mt-2 ml-2 mb-4 d-flex justify-space-between">
      <p class="font-primary font-weight-bold font-weight-medium body-1 mt-2" v-bind:style="{ 'color':COR_SUBTITULO }">
        Escritura
      </p>
      <div v-if="store_Proposta.acao == 'C'">
        <v-btn 
          @click="store_Proposta.acao = 'E';"
          class="btn mr-2" elevation="0">
          <v-icon class="btn-icon mr-1" color="green accent-4">mdi-pencil-outline</v-icon>
          <span class="text-none font-weight-medium" v-bind:style="{ 'color':COR_SUBTITULO }">Editar</span>
        </v-btn>
        <v-btn 
          @click="dialog_excluir = true;"
          class="btn" elevation="0">
          <v-icon class="btn-icon mr-1" color="red accent-4">mdi-delete-outline</v-icon>
          <span class="text-none font-weight-medium" v-bind:style="{ 'color':COR_SUBTITULO }">Deletar</span>
        </v-btn>
      </div>
    </v-toolbar-title>
    <!-- FIM TÍTULO DA PÁGINA -->
    <!-------- FORM REMONTAR ----------------------------->
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      class="form mx-1">
      <v-row>
        <v-col cols="12" sm="12" class="pt-1 px-2 pb-0">
          <v-autocomplete
          v-model="dados.cartorio"
          background-color="#FFF"
          :items="array_cartorios"
          label="Cartório"
          outlined
          item-value="codigo"
          item-text="nome"
          :readonly="store_Proposta.acao == 'C'"
          required
          dense
          :rules="cartorioRules">
            <!-- <template v-slot:append-outer>
              <v-icon color="primary"
              >mdi-plus-circle</v-icon>
            </template> -->
          </v-autocomplete>
        </v-col> 
      </v-row>
      <v-row class="">
        <v-col cols="12" sm="3" class="pt-1 px-2 pb-0">
          <v-text-field
            v-model="dados.livro"
            light
            loader-height="1"
            background-color="#FFF"
            label="Livro"
            placeholder="Livro"
            filled
            outlined
            :readonly="store_Proposta.acao == 'C'"
            required
            dense
            :rules="livroRules">
          </v-text-field>
        </v-col>
      
        <v-col cols="12" sm="3" class="pt-1 px-2 pb-0">
          <v-text-field
            v-model="dados.pag_inicial"
            light
            loader-height="1"
            background-color="#FFF"
            label="Página inicial"
            placeholder="Página inicial"
            filled
            outlined
            :readonly="store_Proposta.acao == 'C'"
            required
            dense
            :rules="numeroRules"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="3" class="pt-1 px-2 pb-0">
          <v-text-field
            v-model="dados.pag_final"
            light
            loader-height="1"
            background-color="#FFF"
            label="Página final"
            placeholder="Página final"
            filled
            :readonly="store_Proposta.acao == 'C'"
            outlined
            required
            dense
            :rules="numeroRules"
          ></v-text-field>
        </v-col>
       
        <v-col cols="12" sm="3" class="pt-1 px-2 pb-0">
          <DatePicker
            v-model="store_Proposta.proposta_selecionado.empreendvenda_escr_data"
            label="Data da Lavratura"
            placeholder="dia/mês/ano"
            class="mr-0 "
            outlined
            backgroundColor="#FFF"
            tabindex="0"
            :readonly="store_Proposta.acao == 'C'"
            dense
            format="DD/MM/YYYY">
          </DatePicker>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="5" class="pt-1 px-2 pb-0">
          <v-text-field
            v-model="dados.outorgado"
            light
            loader-height="1"
            background-color="#FFF"
            label="Outorgado"
            placeholder="Outorgado"
            outlined
            required
            readonly
            dense>
            <template v-slot:append-outer >
              <v-icon :disabled="store_Proposta.acao == 'C'" @click="abreDialogoPessoa()" color="primary"
              >mdi-magnify</v-icon>
            </template>
          </v-text-field>
        </v-col>

        <v-col cols="12" sm="3"  class="pt-1 px-2 pb-0">
          <v-text-field
          v-model="dados.cpf_cnpj"
          v-mask="MASK_CPFCNPJ"
          light
          loader-height="1"
          background-color="#FFF"
          label="CPF/CNPJ"
          placeholder="CPF/CNPJ"
          outlined
          required
          readonly
          dense
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="3"  class="pt-1 px-2 pb-0">
          <v-text-field
            v-model="dados.telefone"
            v-mask="MASK_TELEFONE"
            light
            loader-height="1"
            background-color="#FFF"
            label="Telefone"
            placeholder="Telefone"
            outlined
            readonly
            required
            dense>
          </v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="12"  class="pt-1 px-2 pb-0">
          <v-textarea
            v-model="dados.observacoes"
            rows="1"
            auto-grow
            counter="255" 
            maxlength="255"
            background-color="#FFF"
            label="Observações"
            placeholder="Observações"
            outlined
            :readonly="store_Proposta.acao == 'C'"
            dense
            ></v-textarea>
        </v-col>
      </v-row>

    </v-form>
    <!-- FIM FORM REMONTAR -->    

    <!-- CONTAINER EDITOR -------------------------------------------------------->
    <!-- <v-container class="mx-0 pa-0 container d-none">
      <v-card elevation="0" class="card rounded-lg mx-0 px-0">
        <template>
          <v-toolbar
          flat
          class="rounded-lg" -->
          >
            <!-- MODELO DE RESCISAO -------------------->
            <!-- <v-col cols="12" sm="4" class="mt-10 mr-4 pt-0 px-0 ">
              <v-select
              label="Modelo da autorização"
              v-model="dados.modelo"
              background-color="#tran"
              solo
              dense
              flat
              :items="modelo_autorizacao"
              item-text="name"
              ></v-select>
            </v-col>  -->
            <!-- MODELO DE RESCISAO -------------------->

            <!-- <v-btn 
              class="mr-4 pr-6" elevation="0">
              <v-icon class="btn-icon mr-1" v-bind:style="{ 'color':COR_SUBTITULO }">mdi-sync-circle</v-icon>
              <span class="caption font-weight-medium" v-bind:style="{ 'color':COR_SUBTITULO }">Remontar Autorização</span>
            </v-btn>

            <v-btn 
            class="pr-6" elevation="0">
              <v-icon class="btn-icon mr-1" v-bind:style="{ 'color':COR_SUBTITULO }">mdi-printer</v-icon>
              <span class="caption font-weight-medium" v-bind:style="{ 'color':COR_SUBTITULO }">Imprimir</span>
            </v-btn> -->

            <!-- <v-spacer></v-spacer> -->
          <!-- </v-toolbar>
        </template> -->

        <!-- EDITOR -------------------------------------------------------->
        <!-- <v-card-text>
            <div style="background: #FFFFFF; width: 100%; height: 400px;">
            <iframe frameBorder="0" width='100%' height='1000' src="https://docs.google.com/document/d/1KdFYg7C3qIAZKW-qnmLfpHyll6U7JUPqt0yP6G63pUM/edit#heading=h.6jynaot9cbnq/pub?embedded=true"></iframe>             -->
            <!-- https://codepen.io/Eazymov/full/MEzGYv/ -->
            <!-- https://openbase.com/js/vue2-editor -->
            <!-- https://github.surmon.me/vue-quill-editor/ -->
            <!-- https://openbase.com/js/@toast-ui/vue-editor#-examples -->
            <!-- http://hifarer.github.io/vueditor/ -->
            <!-- https://donotebase.github.io/quasar-tiptap/examples/all -->
            <!-- https://openbase.com/js/mavon-editor -->
            <!-- </div>
        </v-card-text> -->
        <!-- FIM EDITOR -------------------------------------------------------->
      <!-- </v-card>
    </v-container> -->
    <!-- FIM CONTAINER EDITOR -------------------------------------------------------->

    <v-divider class="divider mt-3"></v-divider>

    <v-footer
      v-if="store_Proposta.acao !== 'C'"
      color="transparent"
      elevation="0"
      class="footer justify-center pt-3">
        <v-btn
        id="btn_cancelar"
        ref="btn_cancelar"
        class="mr-4 caption font-weight-medium"
        color="primary"
        text
        @click="store_Proposta.acao = 'C'"
        >
          Cancelar
        </v-btn>

      <v-btn
      :readonly="!valid"
      @click="validate()"
      class="btn caption font-weight-medium white--text"
      color="primary accent-4">
        Salvar
      </v-btn>
    </v-footer>

    <v-alert
    :value="alert"
    v-on:click="fecha_alert()"
    color="red"
    dark
    icon="mdi-home"
    transition="scale-transition"
    elevation="1"
    type="warning"
    dense
    >
      {{ alert_msg }}
    </v-alert>

    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snack = false">Close</v-btn>
      </template>
    </v-snackbar>    
    <!-- DIALOGO PESSOA -------------------------------------------------------->
    <v-container v-if="store_Pessoa.dialogPessoa">      
      <v-dialog
        v-model="store_Pessoa.dialogPessoa"
        scrollable
        max-width="500px"
        max-height="600px"
      >
        <v-card>
          <!-- Cabecalho da PESSOA -->
          <v-card-title :style="{ 'background-color':COR_PRINCIPAL }">
            <v-layout nowrap class="mt-n2" style="width:100%; height:28px">
              <div class="pa-0" style="width:100%">
                <p style="color:white; white-space: pre-line; font-size:16px !important;">
                  Busca de Pessoa
                </p>                      
              </div>
              <div class="pa-0 text-center" style="width:40px;">
                <v-btn icon dark v-on:click="store_Pessoa.dialogPessoa = false" class="mt-n2">
                  <v-icon large class="title">mdi-close</v-icon>
                </v-btn>
              </div>
            </v-layout>
          </v-card-title>
          
          <!-- Corpo do PESSOA -->
          <v-card-text class="pa-0" style="height: 500px;">
            <PessoaConsultaMobile
            class="mt-n2"
            :editar_="true"
            />
          </v-card-text>

          <v-divider></v-divider>
          
          <!-- Rodape do PESSOA -->
          <v-card-actions class="d-flex justify-center pa-3" :style="{ 'background-color':COR_SECUNDARIA }">
            <v-btn
              @click="store_Pessoa.dialogPessoa = false; store_Pessoa.acao_abas = false"
              class="mr-4 btn text-none"
              color="primary"
              text
            >
              Cancelar
            </v-btn>
            <v-btn
              class="btn text-none"
              color="primary"
              :disabled="!store_Pessoa.pessoa_selecionado"
              @click="selecionarPessoa(store_Pessoa.pessoa_selecionado)"
            >
              Selecionar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <!-- FIM DIALOGO PESSOA ---------------------------------------------------->

       <!--------- DIALOG EXCLUIR ESCRITURA ------------------------------->
    <v-container v-if="dialog_excluir">
      <v-dialog v-model="dialog_excluir" transition="dialog-bottom-transition" persistent max-width="410" class="pa-0">
        <v-divider></v-divider>
  
        <v-card elevation="0" class="">
          <v-card-title class="text-h6" style="word-break: keep-all">
            Tem certeza que deseja EXCLUIR esta escritura?
          </v-card-title>
          <v-card-text>
            <!-- <span style="font-size: 15px; font-weight:600">{{store_Pessoa.pessoa_selecionado.pessoa_nome}}</span><br> -->
            <!-- Tipo: {{tipo_Aux}}<br> -->
            <!-- Data cadastro: {{cadastro_dt_Aux}}<br> -->
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-spacer></v-spacer>
            <v-btn id="btn_cancelar" ref="btn_cancelar" class="mr-4" color="primary" text @click="dialog_excluir = false">
              Cancelar
            </v-btn>
  
            <v-btn class="btn white--text" color="primary accent-4" :loading="loading" @click="excluir()">
              Excluir
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import store_Proposta from "../store_Proposta";
import store_Pessoa from "../../Pessoas/store_Pessoa";
import store_site from "../../../store/store_site";
import { CpfCnpjIsValid, telefoneIsValid } from "../../../services/funcoes";
import { COR_PRINCIPAL, COR_SECUNDARIA, COR_SUBTITULO, MASK_CPFCNPJ, MASK_TELEFONE } from "../../../services/constantes";
import PessoaConsultaMobile from "../../Pessoas/PessoaConsultaMobile.vue"
import DatePicker from "../../ComponentesGlobais/Date-Picker/date-picker.vue";
import { API } from "../../../services/API"

export default {
  name: "EscrituraRemontagem",

  components: {
    DatePicker,
    PessoaConsultaMobile
  },

  data() {
    return {

      store_Proposta  : store_Proposta,
      store_Pessoa    : store_Pessoa,
      store_site      : store_site,
      COR_SUBTITULO   : COR_SUBTITULO,  
      MASK_CPFCNPJ    : MASK_CPFCNPJ,
      MASK_TELEFONE   : MASK_TELEFONE,
      COR_PRINCIPAL   : COR_PRINCIPAL,
      COR_SECUNDARIA  : COR_SECUNDARIA,
      API             : API,
      
      valid: true,
      alert: false,
      loading : false,
      alert_msg: "",
      array_cartorios : null,
      dialog_excluir  : false,

      dados: {
        cod_pessoa : null,
        cartorio: null,
        livro: "",
        pag_inicial: "",
        pag_final: "",
        outorgardo: "",
        cpf_cnpj: "",
        telefone: "",
        observacoes: "",
      },

    /* CARTORIOS */
      nomes_cartorio: [
         "Cartório 01", "Cartório 02", "Cartório 03" 
      ],

    /* Inputs e Menus Data */
      lavratura_dt: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menuLavratura_dt: false,

    /* Formulário Reprovação */
      snack: false,
      snackColor: "",
      snackText: "",
      form: false,

      livroRules: [
        (value) => !!value || "O livro é obrigatório",
        (value) => /^\d+$/.test(value) || 'O número não é válido',
        (value) =>
          (value && value.toString().length >= 3) ||
          "O livro deve ter no mínimo 3 caracteres",
        (value) =>
          (value && value.toString().length <= 60) ||
          "O livro deve ter menos do que 60 caracteres",
      ],
      numeroRules: [
        (value) => !!value || "O número é obrigatório",
        (value) => /^\d+$/.test(value) || 'O número não é válido',
        (value) =>
          (value && value.toString().length <= 10) ||
          "O número deve ter menos do que 10 caracteres",
      ],
      cartorioRules: [
        (value) => !!value || "O cartório é obrigatório",
      ],
    };
  },

  mounted(){
    console.log(this.store_Proposta.proposta_selecionado.pess_escr);
    if (!!this.store_Proposta.proposta_selecionado.cod_empreendvenda_escr_cart) {
      this.dados.cartorio = this.store_Proposta.proposta_selecionado.cod_empreendvenda_escr_cart
      this.dados.livro = this.store_Proposta.proposta_selecionado.empreendvenda_escr_livro
      this.dados.pag_inicial = this.store_Proposta.proposta_selecionado.empreendvenda_escr_folha
      this.dados.pag_final = this.store_Proposta.proposta_selecionado.empreendvenda_escr_folha_final
      // this.dados.lavratura_dt = this.store_Proposta.proposta_selecionado.empreendvenda_escr_data
      this.dados.cod_pessoa = this.store_Proposta.proposta_selecionado.cod_empreendvenda_escr_outorg
      this.dados.observacoes = this.store_Proposta.proposta_selecionado.empreendvenda_escr_obs

      this.dados.outorgado = this.store_Proposta.proposta_selecionado.pess_escr.pessoa_nome
      this.dados.telefone = this.store_Proposta.proposta_selecionado.pess_escr.fone_celular1
      this.dados.cod_pessoa = this.store_Proposta.proposta_selecionado.pess_escr.cod_pessoa
      this.dados.cpf_cnpj = this.store_Proposta.proposta_selecionado.pess_escr.pessoa_cpf || this.store_Proposta.proposta_selecionado.pess_escr.pessoa_cnpj

      // this.dados.telefone = 
      //   this.store_Proposta.proposta_selecionado.pessoa_venda.fone_celular1 ? 
      //   this.store_Proposta.proposta_selecionado.pessoa_venda.fone_celular1 : 
      //   this.store_Proposta.proposta_selecionado.pessoa_venda.fone_residencial
    }
    this.cartoriosGet()
  },
  methods: {
    
    async abreDialogoPessoa(){
      if (this.store_Proposta.acao !== 'C') {
        this.store_Pessoa.dialogPessoa = true
      }
    },

    selecionarPessoa(elem) {
        this.dados.outorgado = elem.pessoa_nome
        this.dados.cod_pessoa = elem.cod_pessoa
        this.dados.cpf_cnpj = elem.pessoa_cpf ? elem.pessoa_cpf : elem.pessoa_cnpj
        this.dados.telefone = elem.fone_celular1 ?elem.fone_celular1 : elem.fone_residencial
     
      // this.store_Pessoa.dialogConjugeExcluir  = false
      this.store_Pessoa.dialogPessoa         = false
    },

    async cartoriosGet(){
      const resposta = await API.get("cartorio", {});
      this.array_cartorios =  resposta.data.result
    },

    fecha_alert() {
      this.alert = false;
    },

    async validate () {
      const lb_valido = this.$refs.form.validate();

      if (lb_valido){
        
        var lo_JSON = {
          cod_empreendvenda                : Number(this.$route.params.cod_empreendvenda),
          cod_empreendvenda_escr_cart     : this.dados.cartorio,
          empreendvenda_escr_livro        : this.dados.livro,
          empreendvenda_escr_folha        : this.dados.pag_inicial,
          empreendvenda_escr_folha_final  : this.dados.pag_final,
          empreendvenda_escr_data         : this.store_Proposta.proposta_selecionado.empreendvenda_escr_data,
          cod_empreendvenda_escr_outorg   : this.dados.cod_pessoa,
          empreendvenda_escr_obs          : this.dados.observacoes
        };
      
        var ls_Res;
        this.loading = true;
       
        ls_Res = await this.store_Proposta.EscrituraPut(lo_JSON);

        if (ls_Res.result && ls_Res.result.message && ls_Res.result.message == 'success') {
          this.store_site.alert_cor = "#00A000";
          this.store_site.alert_timeout = 10000;
          this.store_site.alert_msg = ls_Res.result.result.trim();
          this.store_site.alert = true;

          this.store_Proposta.proposta_selecionado.cod_empreendvenda_escr_cart     = this.dados.cartorio,
          this.store_Proposta.proposta_selecionado.empreendvenda_escr_livro        = this.dados.livro,
          this.store_Proposta.proposta_selecionado.empreendvenda_escr_folha        = this.dados.pag_inicial,
          this.store_Proposta.proposta_selecionado.empreendvenda_escr_folha_final  = this.dados.pag_final,
          // this.store_Proposta.proposta_selecionado.empreendvenda_escr_data         = this.dados.lavratura_dt,
          this.store_Proposta.proposta_selecionado.cod_empreendvenda_escr_outorg   = this.dados.cod_pessoa,
          this.store_Proposta.proposta_selecionado.empreendvenda_escr_obs          = this.dados.observacoes

          
        } else {
          this.store_site.alert_cor = "#A00000";
          this.store_site.alert_timeout = 20000;
          this.store_site.alert_msg = ls_Res.errors.trim();
          this.store_site.alert = true;
        }
        this.loading = false;
        this.store_Proposta.dialogEscrituraCriacao = false
        this.store_Proposta.acao = 'C'
      }
    },
    async excluir() {
      var lo_JSON = {
        cod_empreendvenda               : Number(this.$route.params.cod_empreendvenda),
        cod_empreendvenda_escr_cart     : null,
        empreendvenda_escr_livro        : null,
        empreendvenda_escr_folha        : null,
        empreendvenda_escr_folha_final  : null,
        empreendvenda_escr_data         : null,
        cod_empreendvenda_escr_outorg   : null,
        empreendvenda_escr_obs          : null
      };
    
      var ls_Res;
      // this.loading = true;
      
      ls_Res = await this.store_Proposta.EscrituraDelete(lo_JSON);

      if (ls_Res.result && ls_Res.result.message && ls_Res.result.message == 'success') {
        this.store_site.alert_cor = "#00A000";
        this.store_site.alert_timeout = 10000;
        this.store_site.alert_msg = ls_Res.result.result.trim();
        this.store_site.alert = true;

        this.store_Proposta.proposta_selecionado.cod_empreendvenda_escr_cart     = null,
        this.store_Proposta.proposta_selecionado.empreendvenda_escr_livro        = null,
        this.store_Proposta.proposta_selecionado.empreendvenda_escr_folha        = null,
        this.store_Proposta.proposta_selecionado.empreendvenda_escr_folha_final  = null,
        this.store_Proposta.proposta_selecionado.empreendvenda_escr_data         = null,
        this.store_Proposta.proposta_selecionado.cod_empreendvenda_escr_outorg   = null,
        this.store_Proposta.proposta_selecionado.empreendvenda_escr_obs          = null

        
      } else {
        this.store_site.alert_cor = "#A00000";
        this.store_site.alert_timeout = 20000;
        this.store_site.alert_msg = ls_Res.errors.trim();
        this.store_site.alert = true;
      }
      this.loading = false;
      this.dialog_excluir = false
      this.store_Proposta.acao = 'C'
    }
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.name === "xs";
    },
  },
};
</script>

<style scoped>
#EscrituraRemontagem {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  clear: both;
  padding-top: 4px;
  padding-right: 8px;
  width: 100%;
}

@media(max-width: 599px) {
  #EscrituraRemontagem {
    padding-right: 0px;
  }
}

.separador {
  color: #505050!important;
  opacity: 0.6;
}

/* ---------- BARRA DE SCROLL ---------- */
#EscrituraRemontagem::-webkit-scrollbar {
  width: 5px;
}

#EscrituraRemontagem::-webkit-scrollbar-button {
  padding: 1px;
}

#EscrituraRemontagem::-webkit-scrollbar-thumb {
  background: #CECECE; 
  border-radius: 50px;
}

#EscrituraRemontagem::-webkit-scrollbar-thumb:hover {
  background: #A7A7A7; 
  height: 150px;
} 

@media(max-width: 599px) {
  #EscrituraRemontagem::-webkit-scrollbar {
    width: initial;
  }

  #EscrituraRemontagem::-webkit-scrollbar-button {
    padding: initial;
  }

  #EscrituraRemontagem::-webkit-scrollbar-thumb {
    background: initial;
    border-radius: initial;
  }

  #EscrituraRemontagem::-webkit-scrollbar-thumb:hover {
    background: initial;
    height: initial;
  } 
}

.form {
  height: 100%;
}

.container {
  max-width: 100%;
}

.card {
  box-shadow: var(--COR_SOMBRA)!important;
}

.btn {
  width: 100px;
}

.btn-icon {
  font-size: 20px;
}

.divider {
  margin-bottom: 2px;
}

.footer {
  width: 100%;
  position: sticky;
  bottom: 0;
  background: var(--COR_SECUNDARIA)!important;
  box-shadow: #edeff1 0px 1px 4px 0px, #edeff1 0px 1px 3px 0px!important;
}
</style>
